'use client';

import ease from '@/constants/ease';
import useStore from '@/stores/useStore';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { ComponentProps, FC } from 'react';
import Drawer from './Drawer';
import IconShadowHeaderTag from './IconShadowHeaderTag';
import Link, { LinkProps } from './Link';

export interface HeaderMobileLinkProps extends LinkProps {
  type: 'link';
}

export interface HeaderMenuDrawerTriggerProps extends ComponentProps<'button'> {
  title: string;
}

export interface HeaderMobileMenuDrawerProps {
  type: 'drawer';
  title: string;
  sections: {
    title: string;
    links: {
      icon?: React.ReactNode;
      title: string;
      href: string;
      tag?: string;
    }[];
  }[];
}

export interface MobileMenuLinkProps {
  type: 'primary' | 'secondary';
  title: string;
  href: string;
  action?: 'trial' | 'demo';
}

export interface HeaderMobileTakeoverProps {
  menu?: (HeaderMobileMenuDrawerProps | HeaderMobileLinkProps)[];
  menuLinks: MobileMenuLinkProps[];
  onClickMenuButton: (action: 'trial' | 'demo' | undefined) => void;
}

export const HeaderMobileMenuLink: FC<HeaderMobileLinkProps> = ({ href, title, ...props }) => (
  <Link className="block px-container-mobile md:px-container-desktop py-5 text-28/28 font-500" href={href} {...props}>
    {title}
  </Link>
);

export const HeaderMobileMenuDrawer: FC<HeaderMobileMenuDrawerProps> = ({ title, sections = [] }) => {
  const { setMobileMenuOpen, setDesktopHeaderMenuIndex } = useStore();

  const onCloseAllMenus = () => {
    setMobileMenuOpen(false);
    setDesktopHeaderMenuIndex(null);
  };

  const renderTrigger = ({ onClick, isOpen }) => {
    return (
      <button
        className="block w-full px-container-mobile md:px-container-desktop py-5 text-28/28 font-500"
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <span className="block">{title}</span>
          <motion.div
            animate={{
              rotate: isOpen ? 180 : 0,
            }}
            transition={{
              duration: 600 / 1000,
              ease: ease.appear,
            }}
          >
            <Image width={24} height={24} className="block" src="/images/icon-arrow-drawer.svg" alt="" />
          </motion.div>
        </div>
      </button>
    );
  };

  const drawerContent = (
    <div className="flex flex-col gap-4 px-container-mobile md:px-container-desktop pb-4">
      {sections.map((section, index) => (
        <div key={index} className="flex flex-col gap-2">
          <p className="text-14/20 font-500">{section.title}</p>
          <ul className="flex flex-col gap-2">
            {section.links.map((link, index) => (
              <li key={index}>
                <Link href={link.href} onClick={onCloseAllMenus} className="text-20/28 flex gap-1 w-full items-center">
                  {link.icon}
                  <div>{link.title}</div>
                  {link.tag && (
                    <div className="relative ml-1">
                      <div className="absolute inset-[-8px]">
                        <IconShadowHeaderTag
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          className="absolute left-0 top-0"
                        />
                      </div>
                      <div className="relative top-[1px] bg-white text-gray-500 block rounded-4 px-half text-10/15 h-[15px] uppercase font-mono">
                        {link.tag}
                      </div>
                    </div>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );

  return <Drawer renderTrigger={renderTrigger}>{drawerContent}</Drawer>;
};

const HeaderMobileTakeover: FC<HeaderMobileTakeoverProps> = ({
  menu = [],
  menuLinks = [],
  onClickMenuButton = () => { },
}) => {
  const { isMobileMenuOpen, setMobileMenuOpen, setDesktopHeaderMenuIndex } = useStore();

  const onCloseAllMenus = () => {
    setMobileMenuOpen(false);
    setDesktopHeaderMenuIndex(null);
  };

  const rows = menu.map((row, index) => {
    switch (row.type) {
      case 'link':
        return (
          <div key={index} className="border-b border-solid border-gray-200">
            <HeaderMobileMenuLink key={index} {...row} onClick={onCloseAllMenus} />
          </div>
        );
      case 'drawer':
        return (
          <div key={index} className="border-b border-solid border-gray-200">
            <HeaderMobileMenuDrawer key={index} {...row} />
          </div>
        );
      default:
        return null;
    }
  });

  const footer = (
    <motion.div
      initial={{ y: '100%' }}
      animate={{ y: 0 }}
      exit={{ y: '100%' }}
      transition={{
        duration: 300 / 1000,
        ease: ease.appear,
      }}
      className="fixed bottom-0 left-0 right-0 bg-white p-container-mobile"
    >
      <div className="absolute left-0 right-0 bottom-full h-6 pointer-events-none bg-gradient-to-b from-transparent to-white" />
      <div className="grid grid-cols-2 gap-1">
        {menuLinks.map((link, index) => {
          return link.href.length ? (
            <Link
              key={index}
              className={cx('block w-full button button-size-medium xs:button-size-large', {
                'button-primary-violet': link.type === 'primary',
                'button-secondary-gray': link.type === 'secondary',
              })}
              href={link.href}
              onClick={onCloseAllMenus}
            >
              {link.title}
            </Link>
          ) : (
            <button
              key={index}
              className={cx('block', {
                'button button-size-medium button-primary-violet': link.type === 'primary',
                'button button-size-medium button-secondary-gray': link.type === 'secondary',
              })}
              onClick={() => {
                onClickMenuButton(link.action);
                onCloseAllMenus();
              }}
            >
              {link.title}
            </button>
          );
        })}
      </div>
    </motion.div>
  );

  return (
    <AnimatePresence>
      {isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 300 / 1000,
            ease: ease.appear,
          }}
          className="2xl:hidden fixed inset-0 z-9 bg-white scroll-touch overflow-y-auto pt-12 pb-15"
        >
          {rows}
          {footer}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HeaderMobileTakeover;
